<template>
  <div class="prescription-preview-panel">
    <div class="layout-horizontal">
      <span class="layout-inflexible font-size-medium">
        <span class="font-weight-bold">饮食处方</span>
      </span>
      <span class="layout-flexible"></span>
      <ys-popover class="layout-inflexible">
        <div class="btn-management font-weight-bold font-color-secondary font-size-extra-small" slot="reference">
          <span>管理</span>
          <span v-if="totalCount > 0">({{ totalCount }})</span>
          <i class="fas fa-caret-right font-size-small margin-left-small"></i>
        </div>
        <div>
          <ys-button @click="handleSendPrescriptionClick">
            <span><i class="fas fa-plus-circle"></i> 发送饮食处方</span>
          </ys-button>
          <div class="margin-top-large font-align-right font-weight-bold font-color-secondary">
            <span class="btn-all-prescription" @click="handleAllPrescriptionClick">全部饮食处方 <i class="fas fa-caret-right"></i></span>
          </div>
        </div>
      </ys-popover>
    </div>
    <div class="layout-horizontal layout-wrap margin-top">
      <div
          class="prescription layout-horizontal"
          :class="{
             'is-stopped': prescription.state !== BusinessState.STARTED,
          }"
          v-for="prescription in prescriptionList"
          :key="prescription.id"
          @click="handlePrescriptionClick(prescription)"
      >
        <div class="prescription-icon">
          <i class="fas fa-prescription-bottle"></i>
        </div>
        <div class="margin-left" style="overflow: hidden">
          <div class="font-size-base font-nowrap font-weight-bold">{{ prescription.title }}</div>
          <div class="font-color-placeholder margin-top-small">
            <span class="mark-started" v-if="prescription.state === BusinessState.STARTED">
              <i class="fa fa-play"></i>
              执行中
            </span>
            <span class="font-color-danger" v-else>已停止</span>
          </div>
        </div>
      </div>
    </div>
    <prescription-template-picker-dialog
        :visible.sync="prescriptionTemplatePickerDialogVisible"
        :organization-id="organizationId"
        @pick="handlePrescriptionPicked"
    ></prescription-template-picker-dialog>
    <prescription-input-dialog
        ref="prescriptionInputDialog"
        :visible.sync="prescriptionInputDialogVisible"
        :template-id="prescriptionTemplatePicked ? prescriptionTemplatePicked.id : null"
        :organization-id="organizationId"
        :user-id="userId"
        @confirm="handlePrescriptionInputConfirm"
    ></prescription-input-dialog>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import BusinessState from "@/assets/javascript/business-state";
import YsPopover from "@/components/wedigets/YsPopover";
import YsButton from "@/components/wedigets/YsButton";
import PrescriptionTemplatePickerDialog from "@/components/dialog/PrescriptionTemplatePickerDialog";
import PrescriptionInputDialog from "@/pages/prescription/dialog/PrescriptionInputDialog";

function doLoadPrescriptionList() {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/prescription/list/get/by_organization',
    data: {
      userId: this.userId,
      organizationId: this.organizationId,
      pageNum: 1,
      pageSize: 1,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.prescriptionList = res.data.list;
    this.totalCount = res.data.total;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "PrescriptionPreviewCard",
  components: {PrescriptionInputDialog, PrescriptionTemplatePickerDialog, YsButton, YsPopover},
  mixins: [httpapi],
  props: {
    organizationId: Number,
    userId: Number,
  },
  data() {
    return {
      BusinessState,

      loadingCode: 1,
      busyLoadingCode: 0,
      prescriptionList: [],
      totalCount: 0,

      prescriptionTemplatePickerDialogVisible: false,
      prescriptionTemplatePicked: null,

      prescriptionInputDialogVisible: false,
    }
  },
  computed: {
    inputParams() {
      const {organizationId, userId} = this;
      return {
        organizationId,
        userId,
      }
    }
  },
  watch: {
    inputParams: {
      handler() {
        this.loadingCode++;
        this.prescriptionList = [];
        this.totalCount = 0;
        if (this.userId && this.organizationId) {
          this.loadPrescriptionList();
        }
      },
      immediate: true,
    }
  },
  methods: {
    loadPrescriptionList() {
      doLoadPrescriptionList.bind(this)();
    },
    handleSendPrescriptionClick() {
      this.prescriptionTemplatePickerDialogVisible = true;
    },
    handlePrescriptionPicked(prescriptionTemplateList) {
      this.prescriptionTemplatePicked = prescriptionTemplateList[0];
      this.prescriptionInputDialogVisible = true;
    },
    handlePrescriptionInputConfirm() {
      this.loadPrescriptionList();
    },
    handleAllPrescriptionClick() {
      this.$router.push({name: 'prescription_list'})
    },
    handlePrescriptionClick(prescription) {
      this.$router.push({name: 'prescription_list', query: {id: prescription.id}})
    }
  },
  mounted() {
    window.eventBus.$on('update:prescription', this.loadPrescriptionList);
  },
  destroyed() {
    window.eventBus.$off('update:prescription', this.loadPrescriptionList);
  }
}
</script>

<style scoped>

.prescription-preview-panel {
  background-color: white;
  padding: 16px 16px 32px;
  border-radius: 16px;
}

.btn-management {
  cursor: pointer;
}

.prescription {
  padding: 8px 0px;
  border-radius: 8px;
  width: 320px;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
}

.prescription:hover {
  background-color: #f1f1f1;
}

.prescription.is-stopped {
  opacity: .5;
}

.prescription .prescription-icon {
  background-color: #d88198;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #f8d2dc;
  opacity: .75;
}

.mark-started {
  background-color: #d88198;
  color: white;
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 8px;
  line-height: 8px;
}

.btn-all-prescription {
  cursor: pointer;
  user-select: none;
}


.btn-management {
  visibility: visible;
}

.prescription-preview-panel:hover .btn-management {
  visibility: visible;
}

</style>