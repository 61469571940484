<template>
  <div class="report-preview-card">
    <div class="layout-horizontal">
      <span class="layout-inflexible">
        <span class="font-weight-bold font-size-medium">健康报告</span>
      </span>
      <span class="layout-flexible"></span>
      <ys-popover class="layout-inflexible">
        <div
            class="btn-management font-color-secondary layout-inflexible font-weight-bold font-size-extra-small"
            slot="reference"
        >
          <span>管理</span>
          <span v-if="totalCount > 0">({{ totalCount }})</span>
          <i class="fas fa-caret-right font-size-small margin-left-small"></i>
        </div>
        <div>
          <ys-button @click="handleSendReport">
            <span><i class="fas fa-plus-circle"></i> 发送报告</span>
          </ys-button>
          <div class="margin-top-large font-align-right">
            <span class="btn-management font-color-secondary font-weight-bold" @click="handleAllReportClick">全部健康报告 <i class="fas fa-caret-right font-size-small"></i></span>
          </div>
        </div>
      </ys-popover>
    </div>
    <div class="layout-horizontal layout-wrap margin-top">
      <div
          class="report layout-horizontal layout-middle"
          v-for="report in reportList" :key="report.id"
          @click="handleReportClick(report)"
      >
        <div class="report-icon layout-inflexible">
          <i class="fas fa-file-medical"></i>
        </div>
        <div class="margin-left" style="overflow: hidden">
          <div class="font-size-base font-weight-bold font-nowrap">{{ report.title }}</div>
          <div class="font-size-small font-color-placeholder margin-top-small">发送时间：{{ TimeUtils.formatNormal(report.time) }}</div>
        </div>
      </div>
    </div>
    <report-template-picker-dialog :visible.sync="reportTemplatePickerDialogVisible" :organization-id="organizationId" @pick="handleReportTemplatePicked"></report-template-picker-dialog>
    <report-input-dialog
        :visible.sync="reportInputDialogVisible"
        :organization-id="organizationId"
        :user-id="userId"
        :template-id="reportTemplatePickedId"
        @confirm="handleReportInputConfirm"
    ></report-input-dialog>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import {TimeUtils} from "@/assets/javascript/time-utils";
import YsPopover from "@/components/wedigets/YsPopover";
import YsButton from "@/components/wedigets/YsButton";
import ReportTemplatePickerDialog from "@/components/dialog/ReportTemplatePickerDialog";
import ReportInputDialog from "@/pages/report/dialog/ReportInputDialog";

function doLoadReportList() {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/report/list/get/by_organization',
    data: {
      userId: this.userId,
      organizationId: this.organizationId,
      pageNum: 1,
      pageSize: 1,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.reportList = res.data.list;
    this.totalCount = res.data.total;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "ReportPreviewCard",
  components: {ReportInputDialog, ReportTemplatePickerDialog, YsButton, YsPopover},
  mixins: [httpapi],
  props: {
    userId: Number,
    organizationId: Number,
  },
  data() {
    return {
      TimeUtils,

      loadingCode: 1,
      busyLoadingCode: 0,

      reportList: [],
      totalCount: 0,

      reportTemplatePickerDialogVisible: false,
      reportTemplatePickedId: null,
      reportInputDialogVisible: false,
    }
  },
  computed: {
    inputParams() {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    }
  },
  watch: {
    inputParams: {
      handler() {
        this.loadingCode++;
        this.reportList = [];
        this.totalCount = 0;
        if (this.userId && this.organizationId) {
          this.loadReportList();
        }
      },
      immediate: true,
    }
  },
  methods: {
    loadReportList() {
      doLoadReportList.bind(this)();
    },
    handleSendReport() {
      this.reportTemplatePickerDialogVisible = true;
    },
    handleReportTemplatePicked(reportTemplateList) {
      this.reportTemplatePickedId = reportTemplateList[0].id;
      this.reportInputDialogVisible = true;
    },
    handleAllReportClick() {
      this.$router.push({name: 'report_list'});
    },
    handleReportClick(report) {
      this.$router.push({name: 'report_list', query: {id : report.id}});
    },
    handleReportInputConfirm() {
      this.loadReportList();
    }
  },
  mounted() {
    window.eventBus.$on('update:report', this.loadReportList);
  },
  destroyed() {
    window.eventBus.$off('update:report', this.loadReportList);
  }
}
</script>

<style scoped>

.report-preview-card {
  background-color: white;
  padding: 16px 16px 32px;
  border-radius: 16px;
}

.btn-management {
  cursor: pointer;
}

.report {
  padding: 8px 0px;
  border-radius: 8px;
  width: 320px;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
}

.report:hover {
  background-color: #f1f1f1;
}

.report .report-icon {
  background-color: #d88198;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #f8d2dc;
  opacity: .75;
}

.btn-management {
  visibility: visible;
}

.report-preview-card:hover .btn-management {
  visibility: visible;
}
</style>