<template>
  <div class="punch-record-panel layout-horizontal">
    <el-scrollbar ref="scrollBar" class="layout-flexible">
      <div class="layout-horizontal">
        <div class="punch-record padding layout-flexible">
          <div class="punch-record-card">
            <div class="font-weight-bold layout-horizontal">
              <span class="layout-inflexible font-size-medium">打卡记录</span>
              <div class="layout-flexible"></div>
              <ys-popover class="layout-inflexible" v-model="punchInstanceListPopoverVisible">
                <div class="font-color-secondary font-size-extra-smallgfgfdfds" slot="reference">
            <span v-if="punchInstance">
              <i class="fa fa-stop font-size-extra-small" v-if="punchInstance.state !== BusinessState.STARTED"></i>
              <i class="fa fa-play font-size-extra-small" v-else></i>
              {{ punchInstance.punch.title }}
              <i class="fas fa-caret-down"></i></span>
                  <span v-else>全部打卡({{ totalPunchInstanceCount }}) <i class="fas fa-caret-down"></i></span>
                </div>
                <ys-infinite-scroll-view style="height:40vh;" @loadmore="loadMorePunchInstance">
                  <div class="layout-horizontal">
                    <ys-button @click="handleStartPunchClick"><span><i class="fas fa-plus-circle"></i> 开始打卡</span>
                    </ys-button>
                    <div class="layout-flexible"></div>
                    <ys-button type="secondary" class="margin-left" @click="handlePunchMgmtClick">
                      <span>管理打卡 <i class="fas fa-caret-right"></i></span>
                    </ys-button>
                  </div>
                  <div class="divider margin-top-large"></div>
                  <div class="punch-instance-list margin-top-small font-size-medium">
                    <div class="punch-instance-list-item" @click="handlePunchInstanceListItemClick(null)">
                      全部打卡({{ totalPunchInstanceCount }})
                    </div>
                    <div class="punch-instance-list-item layout-horizontal layout-middle"
                         v-for="punchInstance in punchInstanceList" :key="punchInstance.id"
                         @click="handlePunchInstanceListItemClick(punchInstance)">
                      <div class="state-stopped-wrapper" v-if="punchInstance.state !== BusinessState.STARTED">
                        <div class="state-stopped font-size-extra-small">已停止</div>
                      </div>
                      <span class="layout-flexible"><i class="far fa-clock"></i> {{ punchInstance.punch.title }}</span>
                      <div class="layout-inflexible operations margin-left">
                        <el-tooltip
                            :content="punchInstance.state !== BusinessState.STARTED ? '重新开始' : '停止打卡'"
                            :open-delay="500">
                          <ys-button type="secondary" size="normal" lighting round
                                     @click.stop="handleStopPunchClick(punchInstance)">
                            <div class="font-size-small">
                              <i class="fa fa-stop font-size-small" style="color: #f091a6"
                                 v-if="punchInstance.state === BusinessState.STARTED"></i>
                              <i class="fa fa-play font-size-small" style="color: #bec0c0" v-else></i>
                            </div>
                          </ys-button>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </ys-infinite-scroll-view>
              </ys-popover>
            </div>
            <div v-if="statistics">
              <div class="date-list layout-horizontal layout-center margin-vertical-large">
                <div class="date-item"
                     :class="{
                 'state-empty': dateItem.state === PunchStatisticalState.EMPTY,
               }"
                     v-for="dateItem in dateStateList" :key="dateItem.date"
                     @click="handleDateItemClick(dateItem.dateStr)">
                  <span>{{ dateItem.date }}</span>
                  <div class="state-dot__wrapper">
                    <div
                        :class="['state-dot',
                  {
                    'state-finished': dateItem.state === PunchStatisticalState.FINISHED,
                    'state-missed': dateItem.state === PunchStatisticalState.MISSED,
                    'state-finished-partly': dateItem.state === PunchStatisticalState.FINISHED_PARTLY,
                  }
                  ]"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="margin-top-large layout-center layout-horizontal layout-middle">
                <span>本周打卡：</span>
                <span class="font-color-primary">{{ statistics.weekRecordCount }}</span>
                <span>次</span>
                <div class="divider-horizontal margin-horizontal"></div>
                <span>缺卡：</span>
                <span class="font-color-danger">{{ statistics.weekMissedCount }}</span>
                <div class="divider-horizontal margin-horizontal"></div>
                <span>补打卡：</span>
                <span class="font-color-warning">{{ statistics.weekLateCount }}</span>
              </div>
            </div>
            <div v-else class="font-color-secondary font-size-medium padding">
              <span>该用户无打卡</span>
              <ys-button type="secondary" lighting class="margin-left" @click="handleStartPunchClick">
                <span><i class="fas fa-plus-circle"></i> 开始打卡</span>
              </ys-button>
            </div>
          </div>
          <scheme-instance-card
              style="margin-top: 10px;"
              :organization-id="organizationId"
              :user-id="userId"></scheme-instance-card>
          <questionnaire-record-previous-panel
              style="margin-top: 10px;"
              :organization-id="organizationId"
              :user-id="userId"
          ></questionnaire-record-previous-panel>
          <prescription-preview-card
              style="margin-top: 10px;"
              :organization-id="organizationId"
              :user-id="userId"
          ></prescription-preview-card>
          <report-preview-card
              style="margin-top: 10px; margin-bottom: 30px;"
              :organization-id="organizationId"
              :user-id="userId"
          ></report-preview-card>
        </div>
      </div>
    </el-scrollbar>
    <punch-record-list-panel class="punch-record-list layout-inflexible layout-self-stretch"
                             ref="punchRecordListPanel"
                             :organization-id="organizationId" :user-id="userId"
                             :punch-instance-id="punchInstance ? punchInstance.id : null" :date="new Date()"
                             :account-id="accountId">
    </punch-record-list-panel>
    <punch-picker-dialog
        :visible.sync="punchPickDialogVisible"
        :organization-id="organizationId"
        :max-selected-count="1"
        :default-selected-ids="defaultSelectedPunchIds"
        @pick="handlePunchPicked"
    ></punch-picker-dialog>
    <delete-dialog
        :visible.sync="stopPunchRemindDialogVisible"
        confirm-button-text="停止"
        :message="`您确定要停止${stoppingPunchInstance ? stoppingPunchInstance.punch.title : '打卡'}吗？`"
        @confirm="handleStopPunchConfirm"
    ></delete-dialog>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import YsPopover from "@/components/wedigets/YsPopover";
import YsButton from "@/components/wedigets/YsButton";
import {TimeUtils} from "@/assets/javascript/time-utils";
import BusinessState from "@/assets/javascript/business-state";
import {PunchStatisticalState} from "@/assets/javascript/punch-utils";
import PunchPickerDialog from "@/components/dialog/PunchPickerDialog";
import DeleteDialog from "@/components/dialog/DeleteDialog";
import PunchRecordListPanel from "@/pages/punch_record/panel/PunchRecordListPanel";
import SchemeInstanceCard from "@/pages/scheme_instance/SchemeInstanceCard";
import QuestionnaireRecordPreviousPanel from "@/pages/questionnaire_record/panel/QuestionnaireRecordPreviousPanel";
import PrescriptionPreviewCard from "@/pages/prescription/panel/PrescriptionPreviewCard";
import ReportPreviewCard from "@/pages/report/panel/ReportPreviewCard";

function doLoadPunchInstanceList(userId, organizationId, pageNum) {
  if (this.loadingCode === this.busyLoadingCode) {
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/punch/instance/list/get/by_organization',
    data: {
      userId,
      organizationId,
      pageNum,
      pageSize: 20,
    }
  }).then(res => {
    if (this.loadingCode !== loadingCode) return;
    if (this.requireRefresh) this.punchInstanceList = [];
    this.requireRefresh = false;
    this.$appendAfter(this.punchInstanceList, res.data.list);
    this.pageNum = pageNum;
    this.totalPages = res.data.pages;
    this.totalPunchInstanceCount = res.data.total;
    if (this.pageNum < this.totalPages) {
      this.busyLoadingCode = 0;
      doLoadPunchInstanceList.bind(this)(userId, organizationId, this.pageNum + 1);
    }
  }).catch(() => {
    if (this.loadingCode !== loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (this.loadingCode !== loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadPunchInstanceStatistics(id, organizationId) {
  let loadingCode = this.loadingCode;
  this.$reqGet({
    path: '/punch/instance/statistics/get',
    data: {
      id,
      organizationId,
      date: TimeUtils.format('yyyy-MM-dd', new Date()),
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.statistics = res.data;
  });
}

function doLoadPunchStatistics(userId, organizationId) {
  let loadingCode = this.loadingCode;
  this.$reqGet({
    path: '/punch/instance/statistics/get/by_organization',
    data: {
      userId,
      organizationId,
      date: TimeUtils.format('yyyy-MM-dd', new Date()),
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.statistics = res.data;
  })
}

function doStartPunch(punchId, userId, organizationId) {
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/punch/start',
    data: {
      punchId,
      userId,
      organizationId,
    }
  }).then(() => {
    if (loadingCode !== this.loadingCode) return;
    this.requireRefresh = true;
    this.busyLoadingCode = 0;
    this.pageNum = 1;
    this.totalPages = 0;
    this.loadPunchInstanceList(1);
    this.loadPunchInstanceStatistics();
    this.$message.success('已开始打卡');
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doStopPunch(instanceId) {
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/punch/stop',
    data: {
      instanceId,
    }
  }).then(() => {
    if (loadingCode !== this.loadingCode) return;
    for (let punchInstance of this.punchInstanceList) {
      if (punchInstance.id === instanceId) {
        punchInstance.state = BusinessState.STOPPED;
        break;
      }
    }
    this.loadPunchInstanceStatistics();
    this.$message.success('已停止打卡');
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "UserPunchRecordPanel",
  mixins: [httpapi],
  components: {
    ReportPreviewCard,
    PrescriptionPreviewCard,
    QuestionnaireRecordPreviousPanel,
    SchemeInstanceCard,
    PunchRecordListPanel, DeleteDialog, PunchPickerDialog, YsButton, YsPopover, YsInfiniteScrollView
  },
  props: {
    organizationId: Number,
    userId: Number,
    accountId: Number,
  },
  computed: {
    inputParams() {
      return {
        organizationId: this.organizationId,
        userId: this.userId,
      }
    },
    dateStateList() {
      if (!this.statistics) return [];
      let dateStateList = new Array();
      let statisticDate = TimeUtils.parseDate(this.statistics.date);
      for (let n = 0; n < this.statistics.recentPunchPoints.length; n++) {
        let day = new Date(statisticDate.getTime() - (5 - n) * 24 * 3600 * 1000)
        let date = TimeUtils.format('dd', day);
        if (n === this.statistics.recentPunchPoints.length - 1) date = '今';
        dateStateList.push({
          date,
          dateStr: TimeUtils.format('yyyy/MM/dd', day),
          point: this.statistics.recentPunchPoints[n],
          state: this.statistics.recentPunchStates[n],
        })
      }
      return dateStateList;
    },
    defaultSelectedPunchIds() {
      let list = new Array();
      for (let punchInstance of this.punchInstanceList) {
        if (punchInstance.state === BusinessState.STARTED) {
          list.push(punchInstance.punch.id);
        }
      }
      return list;
    }
  },
  data() {
    return {
      BusinessState,
      PunchStatisticalState,
      loadingCode: 1,
      busyLoadingCode: 0,
      requireRefresh: false,
      punchInstanceList: [],
      pageNum: 1,
      totalPages: 0,
      totalPunchInstanceCount: 0,
      statistics: null,
      punchInstance: null,
      stoppingPunchInstance: null,
      punchInstanceStatistics: null,
      punchInstanceListPopoverVisible: false,
      punchPickDialogVisible: false,
      stopPunchRemindDialogVisible: false,
    }
  },
  watch: {
    inputParams: {
      handler() {
        this.loadingCode++;
        this.punchInstanceList = [];
        this.pageNum = 1;
        this.totalPages = 0;
        this.punchInstance = null;
        this.statistics = null;
        if(this.$refs.scrollBar) {
          this.$refs.scrollBar.wrap.scrollTop = 0;
        }
        if (this.userId && this.organizationId) {
          this.loadPunchInstanceList();
          this.loadPunchInstanceStatistics();
        }
      },
      immediate: true,
    }
  },
  methods: {
    loadPunchInstanceList(pageNum = 1) {
      doLoadPunchInstanceList.bind(this)(this.userId, this.organizationId, pageNum);
    },
    loadMorePunchInstance() {
      if (this.pageNum < this.totalPages) {
        this.loadPunchInstanceList(this.pageNum + 1);
      }
    },
    loadPunchInstanceStatistics() {
      if (this.punchInstance) {
        doLoadPunchInstanceStatistics.bind(this)(this.punchInstance.id, this.organizationId);
      } else {
        doLoadPunchStatistics.bind(this)(this.userId, this.organizationId);
      }
    },
    handlePunchInstanceListItemClick(punchInstance) {
      this.punchInstanceListPopoverVisible = false;
      this.punchInstance = punchInstance;
      if (this.userId) {
        this.loadPunchInstanceStatistics();
      }
    },
    handleStartPunchClick() {
      this.punchInstanceListPopoverVisible = false;
      this.punchPickDialogVisible = true;
    },
    handlePunchPicked(punchList) {
      doStartPunch.bind(this)(punchList[0].id, this.userId, this.organizationId);
    },
    handleStopPunchClick(punchInstance) {
      if (punchInstance.state === BusinessState.STARTED) {
        this.stoppingPunchInstance = punchInstance;
        this.stopPunchRemindDialogVisible = true;
      } else {
        doStartPunch.bind(this)(punchInstance.punch.id, this.userId, this.organizationId);
      }
    },
    handleStopPunchConfirm() {
      let stoppingPunchInstance = this.stoppingPunchInstance;
      if (stoppingPunchInstance) {
        doStopPunch.bind(this)(stoppingPunchInstance.id);
      }
    },
    handleDateItemClick(dateStr) {
      this.$refs.punchRecordListPanel.date = dateStr;
    },
    handlePunchMgmtClick() {
      this.$router.push({name: 'punch_instance_list'})
    },
    handlePunchStateChanged() {
      this.requireRefresh = true;
      this.busyLoadingCode = 0;
      this.pageNum = 1;
      this.totalPages = 0;
      if (this.userId && this.organizationId) {
        this.loadPunchInstanceList();
        this.loadPunchInstanceStatistics();
      }
    }
  },
  mounted() {
    window.eventBus.$on("start:punch", this.handlePunchStateChanged);
    window.eventBus.$on("stop:punch", this.handlePunchStateChanged);
  },
  destroyed() {
    window.eventBus.$off("start:punch", this.handlePunchStateChanged);
    window.eventBus.$off("stop:punch", this.handlePunchStateChanged);
  }
}
</script>

<style scoped>

.punch-record {
  background-color: #f8f8f8;
}

.punch-record-card {
  background-color: #f7e7ea;
  padding: 16px;
  border-radius: 16px;
}

.punch-record-list {
  width: 360px;
  background-color: #c2e7b0;
}

.punch-instance-list-item {
  padding: 12px 10px;
  cursor: pointer;
  user-select: none;
}

.punch-instance-list-item:hover {
  background-color: #f1f1f1;
}

.state-stopped-wrapper {
  width: 0;
  height: 0;
}

.state-stopped-wrapper .state-stopped {
  background-color: #f091a6 !important;
  position: relative;
  color: white;
  z-index: 999;
  white-space: nowrap;
  padding: 0;
  height: 16px;
  width: 43px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transform: rotate(30deg);
  left: 100px;
  top: -10px;
  opacity: .8;
}

.date-list {

}

.date-list .date-item {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f091a6;
  box-shadow: 0 0 16px #f091a6;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
}

.date-list .date-item.state-empty {
  background-color: #f1f1f1;
  color: #bec0c0;
}

.date-list .date-item:hover {
  filter: brightness(95%);
}

.state-dot__wrapper {
  width: 0px;
  height: 0px;
}

.state-dot {
  width: 4px;
  height: 4px;
  background-color: #f1f1f1;
  border-radius: 50%;
  position: relative;
  left: -2px;
  top: -1px;
}

.state-dot.state-finished {
  background-color: #98c9a9;
}

.state-dot.state-missed {
  background-color: #ff5500;
}

.state-dot.state-finished_partly {
  background-color: #ff5500;
}

.punch-record-list {
  background-color: white;
}

.triangle__wrapper {
  height: 0px;
}

.triangle {
  width: 0;
  height: 0;
  border-right: 16px solid #c2e7b0;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 0px;
  position: relative;
  top: 48px;
}

.punch-instance-list-item .operations {
  visibility: hidden;
}

.punch-instance-list-item:hover .operations {
  visibility: visible;
}

.divider-horizontal {
  background-color: white;
  width: 1px;
  align-self: stretch;
}
</style>