<template>
  <div class="scheme-instance-card"
       :class="{'is-active': schemeInstance && schemeInstance.state === BusinessState.STARTED}">
    <div class="title layout-horizontal margin-bottom-large">
      <span class="layout-inflexible font-size-medium font-weight-bold">
        <span class="font-weight-bold">干预方案</span>
        <span v-if="schemeInstance && schemeInstance.state === BusinessState.STARTED"> · 第{{
            schemeInstance.days
          }}天</span>
        <span v-else-if="schemeInstance"> · 共干预{{ schemeInstance.days }}天</span>
      <span></span>
      </span>
      <span class="layout-flexible"></span>
      <div class="btn-management font-weight-bold font-color font-size-extra-small font-color-secondary" v-if="schemeInstance"
           @click="handleSchemeMgmtClick">
        <span><i class="fas fa-tasks"></i> 管理干预方案</span>
      </div>
    </div>
    <div class="margin-top-large font-size-medium layout-horizontal layout-top" v-if="schemeInstance"
         style="cursor: pointer"
         @click="handleSchemeMgmtClick">
      <div class="mark-state mark-started font-size-small" v-if="schemeInstance.state === BusinessState.STARTED">
        <span><i class="fa fa-play font-size-extra-small"></i> 干预中</span>
      </div>
      <div class="mark-state mark-stopped font-size-small" v-else>
        <span><i class="fa fa-stop font-size-extra-small"></i> 已停止</span>
      </div>
      <div class="font-weight-bold font-size-base margin-left" style="margin-top: 2px;">{{
          schemeInstance.scheme.title
        }}
      </div>
    </div>
    <div class="layout-horizontal layout-middle margin-top" v-else>
      <span class="font-size-medium font-color-placeholder margin-right">无干预方案</span>
      <ys-button type="secondary" @click="handleStartSchemeClick"><span><i class="fas fa-plus-circle"></i> 开始干预</span>
      </ys-button>
    </div>
    <scheme-picker-dialog :visible.sync="schemePickerDialogVisible" :organization-id="organizationId"
                          :default-selected-scheme-ids="defaultSelectedSchemeIds"
                          @pick="handleSchemePicked"></scheme-picker-dialog>
  </div>
</template>

<script>
import YsButton from "@/components/wedigets/YsButton";
import BusinessState from "@/assets/javascript/business-state";
import SchemePickerDialog from "@/components/dialog/SchemePickerDialog";
import httpapi from "@/assets/javascript/httpapi";

function doLoadSchemeInstance() {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/scheme/instance/list/get/by_organization',
    data: {
      pageNum: 1,
      pageSize: 1,
      userId: this.userId,
      organizationId: this.organizationId,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    if (res.data.list.length > 0) {
      this.schemeInstance = res.data.list[0];
    }
  }).catch(() => {
    if (loadingCode != this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode != this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doStartScheme(scheme) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/scheme/start',
    data: {
      schemeId: scheme.id,
      userId: this.userId,
      organizationId: this.organizationId,
    }
  }).then(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
    doLoadSchemeInstance.bind(this)();
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "SchemeInstanceCard",
  mixins: [httpapi],
  components: {SchemePickerDialog, YsButton},
  props: {
    userId: Number,
    organizationId: Number,
  },
  data() {
    return {
      BusinessState,
      loadingCode: 1,
      busyLoadingCode: 0,
      schemeInstance: null,

      schemePickerDialogVisible: false,
    }
  },
  computed: {
    inputParams() {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    },
    defaultSelectedSchemeIds() {
      let result = new Array();
      if (this.schemeInstance && this.schemeInstance.state === BusinessState.STARTED) {
        result.push(this.schemeInstance.scheme.id);
      }
      return result;
    }
  },
  watch: {
    inputParams: {
      handler() {
        this.loadingCode++;
        this.schemeInstance = null;
        if (this.userId && this.organizationId) {
          this.loadSchemeInstance();
        }
      },
      immediate: true,
    }
  },
  methods: {
    loadSchemeInstance() {
      doLoadSchemeInstance.bind(this)();
    },
    handleStartSchemeClick() {
      this.schemePickerDialogVisible = true;
    },
    handleSchemePicked(schemeList) {
      doStartScheme.bind(this)(schemeList[0]);
    },
    handleSchemeMgmtClick() {
      this.$router.push({name: 'scheme_instance_list'});
    },
    handleSchemeStateChanged() {
      if (this.userId && this.organizationId) {
        this.loadSchemeInstance();
      }
    }
  },
  mounted() {
    window.eventBus.$on('start:scheme', this.handleSchemeStateChanged);
    window.eventBus.$on('stop:scheme', this.handleSchemeStateChanged);
  },
  destroyed() {
    window.eventBus.$off('start:scheme', this.handleSchemeStateChanged);
    window.eventBus.$off('stop:scheme', this.handleSchemeStateChanged);
  }
}
</script>

<style scoped>

.scheme-instance-card {
  border-radius: 16px;
  background-color: white;
  padding: 16px 16px 32px;
}

.mark-state {
  background-color: #f1f1f1;
  padding: 4px 8px;
  border-radius: 8px;
  white-space: nowrap;
}

.mark-started {
  background-color: #f091a6;
  color: white;
  box-shadow: 0 0 16px #f091a6;
}

.mark-stopped {
  color: #7d7c7c;
  background-color: #f1f1f1;
}


.btn-management {
  cursor: pointer;
  visibility: visible;
}

.btn-management:hover {
  color: #3a8ee6;
}

.scheme-instance-card:hover .btn-management {
  visibility: visible;
}
</style>