<template>
  <div class="questionnaire-record-card">
    <div class="layout-horizontal">
      <div class="font-weight-bold font-size-medium layout-inflexible">
      <span>
        问卷
      </span>
      </div>
      <div class="layout-flexible"></div>
      <div class="layout-inflexible font-color-secondary btn-management font-weight-bold">
        <ys-popover class="popover-management">
          <div slot="reference" class="font-size-extra-small">
            <span>管理</span>
            <span v-if="totalCount > 0">({{ totalCount }})</span>
            <i class="fas fa-caret-right font-size-small margin-left-small"></i>
          </div>
          <div>
            <ys-button lighting class="margin-right-large" @click="handleSendQuestionnaireClick">
              <span><i class="fas fa-plus-circle"></i> 发送问卷</span>
            </ys-button>
          </div>
          <div class="margin-top-large font-align-right font-weight-bold font-color-secondary">
            <span class="btn-all-questionnaire" @click="handleAllQuestionnaireRecordClick">
              全部问卷 <i class="fas fa-caret-right font-size-small"></i>
            </span>
          </div>
        </ys-popover>
      </div>
    </div>
    <div class="layout-horizontal layout-wrap margin-top" style="justify-content: space-between;"
         v-if="questionnaireRecordList.length > 0">
      <div class="questionnaire-record layout-horizontal layout-top"
           v-for="questionnaireRecord in questionnaireRecordList" :key="questionnaireRecord.id"
           @click="handleQuestionnaireRecordClick(questionnaireRecord)">
        <div class="layout-inflexible questionnaire-record-icon">
          <i class="fas fa-database"></i>
        </div>
        <div class="margin-horizontal layout-flexible" style="overflow: hidden;">
          <div class="font-size-base font-weight-bold font-nowrap" style="width: 100%;">{{ questionnaireRecord.title }}</div>
          <div v-if="questionnaireRecord.finished" class="layout-horizontal layout-middle margin-top-small" style="margin-top: 2px;">
            <span class="mark-finished">已完成</span>
            <div class="font-size-small font-color-placeholder margin-left-small">
              完成时间：{{ TimeUtils.formatNormal(questionnaireRecord.finishedAt) }}
            </div>
          </div>
          <div v-else class="layout-horizontal layout-middle margin-top-small">
            <span class="mark-unfinished">未完成</span>
            <div class="font-size-small font-color-placeholder margin-left-small">
              发送时间：{{ TimeUtils.formatNormal(questionnaireRecord.time) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="margin-top padding">
      <span class="font-color-placeholder font-size-medium">无问卷记录</span>
      <ys-button class="margin-left" type="secondary" @click="handleSendQuestionnaireClick"><i
          class="fas fa-plus-circle"></i> 发送问卷
      </ys-button>
    </div>
    <questionnaire-picker-dialog :visible.sync="questionnairePickerDialogVisible" :organization-id="organizationId"
                                 :max-selected-count="5"
                                 @pick="handleQuestionnairePicked"></questionnaire-picker-dialog>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import {TimeUtils} from "@/assets/javascript/time-utils";
import YsPopover from "@/components/wedigets/YsPopover";
import YsButton from "@/components/wedigets/YsButton";
import QuestionnairePickerDialog from "@/components/dialog/QuestionnairePickerDialog";

function doLoadQuestionnaireList(pageNum) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/questionnaire/record/list/get/by_organization',
    data: {
      userId: this.userId,
      organizationId: this.organizationId,
      pageNum,
      pageSize: 1,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.questionnaireRecordList = res.data.list;
    this.totalCount = res.data.total;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doSendQuestionnaire() {
  if (this.questionnairePreparedForSending.length == 0) return;
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let questionnaireId = this.questionnairePreparedForSending[0];
  this.questionnairePreparedForSending.splice(0, 1);
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/questionnaire/record/send',
    data: {
      userId: this.userId,
      organizationId: this.organizationId,
      questionnaireId,
    }
  }).then(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
    if (this.questionnairePreparedForSending.length > 0) {
      doSendQuestionnaire.bind(this)();
    } else {
      this.loadQuestionnaireList();
    }
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "QuestionnaireRecordPreviousPanel",
  components: {QuestionnairePickerDialog, YsButton, YsPopover},
  mixins: [httpapi],
  props: {
    userId: Number,
    organizationId: Number
  },
  data() {
    return {
      TimeUtils,

      loadingCode: 1,
      busyLoadingCode: 0,
      questionnaireRecordList: [],
      totalCount: 0,

      questionnairePickerDialogVisible: false,

      questionnairePreparedForSending: [],
    }
  },
  computed: {
    inputParams() {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    }
  },
  watch: {
    inputParams: {
      handler() {
        this.loadingCode++;
        this.questionnaireRecordList = [];
        this.totalCount = 0;
        if (this.userId && this.organizationId) {
          this.loadQuestionnaireList();
        }
      },
      immediate: true,
    }
  },
  methods: {
    loadQuestionnaireList() {
      doLoadQuestionnaireList.bind(this)(1);
    },
    handleQuestionnaireRecordUpdate() {
      if (this.userId && this.organizationId) {
        this.loadQuestionnaireList();
      }
    },
    handleSendQuestionnaireClick() {
      this.questionnairePickerDialogVisible = true;
    },
    handleQuestionnairePicked(questionnaireList) {
      this.questionnairePreparedForSending = this.questionnairePreparedForSending.concat(questionnaireList.map(x => x.id));
      doSendQuestionnaire.bind(this)();
    },
    handleQuestionnaireRecordClick(questionnaireRecord) {
      this.$router.push({name: 'questionnaire_record_list', query: {id: questionnaireRecord.id}});
    },
    handleAllQuestionnaireRecordClick() {
      this.$router.push({name: 'questionnaire_record_list'})
    }
  },
  mounted() {
    window.eventBus.$on('update:questionnaire_record', this.handleQuestionnaireRecordUpdate);
  },
  destroyed() {
    window.eventBus.$off('update:questionnaire_record', this.handleQuestionnaireRecordUpdate);
  }
}
</script>

<style scoped>

.questionnaire-record-card {
  background-color: white;
  padding: 16px 16px 32px;
  border-radius: 16px;
}

.questionnaire-record {
  padding: 8px 0;
  border-radius: 8px;
  width: 320px;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
}

.questionnaire-record:hover {
  background-color: #f1f1f1;
}

.questionnaire-record .questionnaire-record-icon {
  background-color: #66a0ce;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #66bbff;
  opacity: .75;
}

.btn-management {
  cursor: pointer;
}

.mark-finished {
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #66a0ce;
  color: white;
  font-size: 8px;
  line-height: 12px;
}

.mark-unfinished {
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #ff5500;
  color: white;
  font-size: 8px;
  line-height: 12px;
}

.btn-all-questionnaire {
  cursor: pointer;
  user-select: none;
}

.popover-management {
  visibility: visible;
}

.questionnaire-record-card:hover .popover-management {
  visibility: visible;
}

</style>